import moment from 'moment'
import randomColor from '@/services/random-color'
import { fetchStats, fetchMachines } from '@/services/http/machines'
import http from '@/services/http/http';


const state = {
  machine_stats: [],
  from_date: null,
  to_date: null,
  filter: null,
  isLoading: null,
  hiddenSales: [],
  sales_report_payment: null,
  has_data: false
}

const statsParser = (field) => {
  let data = state.machine_stats
  if (data.length) {
    var label_vals = [];
    for(let i =0; i < data.length; i++){
     label_vals.push(...Object.keys(data[i][field]))
    }
    label_vals = label_vals.sort((a,b) => {
      return new Date(a) - new Date(b)
    })
    label_vals = [...new Set(label_vals)]
    var labels = label_vals.map(d => moment(d).format('MM-DD-YYYY'))
    var datasets = data.map(s => {
      var data = label_vals.map(label => s[field][label] || 0)
      return {
        currency: s.currency,
        label: s.machine,
        fill: false,
        data: data,
        borderColor: randomColor(s.machine)
      }
    })
    return { labels, datasets }
  } else {
    return null
  }
}

const getters = {
  sales: () => {
    const sales = []
    state.machine_stats.forEach(m => {
      for (const date of Object.keys(m.sales)) {
        const v = m.sales[date]
        const s = sales.find(s => s.currency === m.currency)
        if (!s) {
          const new_entry = {currency: m.currency, sales: v}
          sales.push(new_entry)
        } else {
          s.sales += v
        }
      }
    })
    sales.forEach(s => {
      state.hiddenSales.forEach(h => {
        if(h.currency === s.currency){
          s.sales -= h.sales
        }
      })
    })

    return sales
  },
  salesLabel: () => {
    if(state.filter === 'custom_date')
      return 'Sales from ' + moment(state.from_date).format('MMM D') + ' to ' + moment(state.to_date).format('MMM D, YYYY');
    else if(state.filter === 'today')
      return 'Sales Today'
    else if(state.filter === 'yesterday')
      return 'Sales Yesterday'
    else if(state.filter === 'this_week')
      return 'Sales This Week'
    else if(state.filter === 'this_month')
      return 'Sales This Month'
    else if(state.filter === 'last_month')
      return 'Sales Since Last Month'
    else if(state.filter === 'last_2_month')
      return 'Sales Last 2 Months'
    else if(state.filter === 'last_3_month')
      return 'Sales Last 3 Months'
    else if(state.filter === 'last_4_month')
      return 'Sales Last 4 Months'
    else if(state.filter === 'last_5_month')
      return 'Sales Last 5 Months'
    else if(state.filter === 'last_6_month')
      return 'Sales Last 6 Months'
    else if (state.filter === 'this_year')
      return 'Sales This Year'
  },
  saleStats: () => {
    return statsParser('sales')
  },
  activeWifiUserStats: () => {
    return statsParser('active_wifi_users')
  },
  totalWifiUserStats: () => {
    return statsParser('total_wifi_users')
  },
  loading: () => {
    return Boolean(state.isLoading)
  },
  salesReportPayment: () => {
    return state.sales_report_payment
  },
  hasData: () => {
    return Boolean(state.has_data)
  }
}

const actions = {
  async syncMachineStats ({dispatch }, params) {
    state.isLoading = true
    const { devices } = await fetchMachines({})
    await Promise.all(devices.map( async (machine) => {
      if (machine.ngrok_url) {
        const ngrok_url = machine.ngrok_url.replace('/admin', '/machine-stats/sync')
        await http.get(ngrok_url).then(() => {}).catch(() => {})
      }
    }))
    dispatch('fetchMachineStats', params)
  },
  async fetchMachineStats({ commit }, params) {
    state.hiddenSales = [];
    state.from_date = params.from_date;
    state.to_date = params.to_date;
    state.filter = params.filter
    const new_params = {
      from_date: params.from_date,
      to_date: params.to_date
    }

    try{
      new_params["category"] = "machine"
      const machines_data = await fetchStats(new_params)
      new_params["category"] = "sale"
      const sales_data = await fetchStats(new_params)
      new_params["category"] = "active_wifi_users"
      const active_wifi_users_data = await fetchStats(new_params)
      new_params["category"] = "total_wifi_users"
      const total_wifi_users_data = await fetchStats(new_params)

      if (machines_data.length > 0) {
        state.has_data = true
      }

      machines_data.map((machine, index) => {
        machine["sales"] = sales_data[index]["sale"]
        machine["active_wifi_users"] = active_wifi_users_data[index]["active_wifi_users"]
        machine["total_wifi_users"] = total_wifi_users_data[index]["total_wifi_users"]
        state.sales_report_payment = sales_data[index]["sales_report_payment"]
      })

      commit('SET_MACHINE_STATS', machines_data)
    }catch(e){
      console.log(e)
    }
    state.isLoading = false;
  },
  clearMachineStats({ commit }) {
    commit('CLEAR_MACHINE_STATS')
  },
  setHiddenSales({commit}, params){
    commit('SET_HIDDEN_SALES',params)
  },
  clearHiddenSales({commit}){
    commit('CLEAR_HIDDEN_SALES')
  }
}

const mutations = {
  'SET_MACHINE_STATS': (state, data) => {
    state.machine_stats = data
  },
  'CLEAR_MACHINE_STATS': (state) => {
    state.machine_stats = []
  },
  'SET_HIDDEN_SALES': (state, data) => {
    const {sales,hidden,currency } = data
    let _sale = 0;

    if(hidden){
      sales.forEach(s => {
        _sale += s
      })

      const has_currency = state.hiddenSales.filter(h => h.currency === currency).length > 0

      if(has_currency){
        state.hiddenSales.forEach(h => {
          if(h.currency === currency){
            h.sales += _sale
          }
        })
      }else{
        state.hiddenSales.push({
          currency: currency,
          sales: _sale
        })
      }
    }
  },
  'CLEAR_HIDDEN_SALES': (state) => {
    state.hiddenSales = []
  }
}

export default {
  state, getters, actions, mutations
}

import { Device } from "@capacitor/device";

export async function getDeviceInfoStr() {
  const info = await Device.getInfo();

  if (info.platform === "web" && navigator.userAgent) {
    return navigator.userAgent;
  }

  return `${info.platform};${info.osVersion};${info.model}`;
}

import { fetchUser } from '@/services/http/account'

const state = {
  time_zone: ''
}

const getters = {
  userTimezone: () => state.time_zone
}

const actions = {
  async fetchUser({ commit }) {
    const data = await fetchUser()
    commit('SET_USER', data)
  }
}

const mutations = {
  'SET_USER': (state, data) => {
    state.time_zone = data.time_zone
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}

import Dashboard from "../views/Dashboard.vue";

const routes = [
  {
    path: "/",
    component: Dashboard,
    children: [
      {
        path: "",
        name: "Home",
        component: () => import("@/views/dashboard/home/Home.vue"),
      },
      {
        path: "pks",
        name: "LicenseKeys",
        component: () =>
          import("@/views/dashboard/license-keys/LicenseKeys.vue"),
      },
      {
        name: "License",
        path: "pks/:id",
        component: () => import("@/views/dashboard/license/License.vue"),
      },
      {
        name: "LicenseOffers",
        path: "pk-offers",
        component: () =>
          import("@/views/dashboard/license-offers/LicenseOffers.vue"),
      },
      {
        name: "Machines",
        path: "machines",
        component: () => import("@/views/dashboard/machines/Machines.vue"),
      },
      {
        name: "Machine",
        path: "machines/:id",
        component: () => import("@/views/dashboard/machine/Machine.vue"),
      },
      {
        name: "Subscriber Bills",
        path: "recurring-sales",
        component: () => import("@/views/dashboard/recurring-sales/RecurringSales.vue"),
      },
      {
        name: "Payment Accounts",
        path: "payment-accounts",
        component: () => import("@/views/dashboard/payment-accounts/PaymentAccounts.vue"),
      },
      {
        name: "Settings",
        path: "settings",
        component: () => import("@/views/dashboard/settings/Settings.vue"),
      },
      {
        name: "LicenseTransferLogs",
        path: "license-transfer-logs",
        component: () => import("@/views/dashboard/license-transfer-logs/TransferLogs.vue")
      },
      {
        name: "SubvendoCredits",
        path: "subvendo-credits",
        component: () => import("@/views/dashboard/subvendo-credits/SubvendoCredits.vue")
      },
      {
        name: "E-cash Sales",
        path: "ecash-sales",
        component: () => import("@/views/dashboard/ecash-sales/EcashSales.vue")
      }
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/logout",
    name: "Logout",
    component: () => import("@/views/Logout.vue"),
  },
  {
    path: "/register",
    name: "Register",   
    component: () => import("@/views/Register.vue"),
  },
  {
    path: "/confirm-account/:id",
    name: "ConfirmAccount",
    component: () => import("@/views/ConfirmAccount.vue"),
  },
  {
    path: "/password-reset",
    name: "RequestPasswordReset",
    component: () => import("@/views/RequestPasswordReset.vue"),
  },
  {
    path: "/password-reset/:id",    
    name: "ResetPassword",
    component: () => import("@/views/ResetPassword.vue"),
  },
  {
    path: "/resend-confirmation-instructions",
    name: "ResendConfirmationInstructions",
    component: () => import("@/views/ResendConfirmationInstructions.vue")
  },
  {
    // will match everything
    path: "*",    
    component: () => import("@/views/NotFound.vue"),
  }
  
];

export default routes;

import http from '@/services/http/http'


export async function fetchSupportedAccTypes() {
  const url = `/api/v1/withdrawal_requests/account_types`
  const res = await http.get(url)
  return res.data
}

export async function createWithdrawalReq(params){
  const url = `/api/v1/withdrawal_requests`
  const res = await http.post(url, params)
  return res.data
}

export async function listWithdrawalReqs(opts) {
  const url = '/api/v1/withdrawal_requests'
  const { data } = await http.get(url, {params: opts})
  return data
}
import Vue from 'vue'
import moment from 'moment'

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY hh:mm')
  }
})


Vue.filter('formatDateOnly', function(value) {
  if (value) {
    return moment(String(value)).format('MMM DD, YYYY')
  }
})

Vue.filter('formatDateAndTime', function(value) {
  if (value) {
    return moment(String(value)).format('MMM DD, YYYY @ hh:mm A')
  }
})
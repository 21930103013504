
import { listProductKeys } from '@/services/http/product_keys'

const state = {
  product_key: {},
  product_keys: [],
  select_mode: false,
  filter: {
    q: '',
    status: null,
    type: null,
    per_page: 15,
  }
}

const getters = {
  allProductKeys: () => state.product_keys,
  selectedProductKeys: () => state.product_keys.filter(p => p.selected),
  productKeysFilter: () => state.filter,
  productKeySelectMode: () => state.select_mode
}

const actions = {

  async fetchProductKeys({ commit }, params) {
    const data = await listProductKeys(params)
    data.product_keys = data.product_keys.filter(key => key.sku_str && key.sku_str !== 'subvendo-credit')
    commit('APPEND_PRODUCT_KEYS', data.product_keys)

    return {
      total_items: data.total_items,
      total_pages: data.total_pages
    }
  },

  clearProductKeys({ commit }) {
    commit('CLEAR_PRODUCT_KEYS')
  },

  selectAllProductKeys({ commit, state }, val) {
    let product_keys = state.product_keys
    product_keys.forEach(k => (k.selected = val))
    commit('SET_PRODUCT_KEYS', product_keys)
  },

  setProductKeysSelectMode({ commit }, mode) {
    commit('SET_PRODUCT_KEYS_SELECT_MODE', mode)
  },

  setProductKeysFilter({ commit}, filter) {
    commit('SET_PRODUCT_KEYS_FILTER', filter)
  },

  resetProductKeysFilter({ commit }) {
    commit('RESET_PRODUCT_KEYS_FILTER')
  }

}

const mutations = {
  'APPEND_PRODUCT_KEYS': (state, data) => {
    state.product_keys.push(...data)
  },
  'SET_PRODUCT_KEYS': (state, data) => {
    state.product_keys = data
  },
  'CLEAR_PRODUCT_KEYS': (state) => {
    state.product_keys = []
  },
  'SET_PRODUCT_KEYS_FILTER': (state, filter) => {
    state.filter = filter
  },
  'SET_PRODUCT_KEYS_SELECT_MODE': (state, mode) => {
    state.select_mode = mode
  },
  'DESELECT_ALL_PRODUCT_KEYS': (state) => {
    state.product_keys.forEach(p => p.selected = false)
  },
  'RESET_PRODUCT_KEYS_FILTER': (state) => {
    state.filter = {
      q: '',
      status: null,
      type: null,
      per_page: 15
    }
  }

}

export default {
  state,
  getters,
  actions,
  mutations
}

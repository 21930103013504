import { getSubvendoCredits, getSubvendoTransferLogs } from '@/services/http/subvendo_credits'
const state = {
  availableCredits: [],
  usedCredits: [],
  is_loading: false,
  transfer_logs_loading: false,
  subvendoLogs: []
}

const getters = {
  availableSubvendoCredits: () => state.availableCredits,
  usedSubvendoCredits: () => state.usedCredits,
  creditLoading: () => Boolean(state.is_loading),
  transferLogsLoading: () => Boolean(state.transfer_logs_loading),
  subvendoTransferLogs: () => state.subvendoLogs
}

const actions = {
  async fetchSubvendoCredits({ commit }) {
    state.is_loading = true
    const data = await getSubvendoCredits()
    commit('APPEND_AVAILABLE_CREDITS', data.available_credits)
    commit('APPEND_USED_CREDITS', data.used_credits)
    state.is_loading = false
  },
  async fetchSubvendoTransferLogs({ commit }) {
    state.transfer_logs_loading = true
    const data = await getSubvendoTransferLogs()
    const _data = []
    data.forEach(d => {
      d.from_user = d.from_user.first_name + " " + d.from_user.last_name + " (" + d.from_user.email + ")"
      d.to_user = d.to_user.first_name + " " + d.to_user.last_name + " (" + d.to_user.email + ")"
      _data.push(d)
    })

    commit('APPEND_CREDITS_TRANSFER_LOGS', _data)
    state.transfer_logs_loading = false
  }
}

const mutations = {
  'APPEND_AVAILABLE_CREDITS': (state, data) => {
    state.availableCredits = data
  },
  'APPEND_USED_CREDITS': (state, data) => {
    state.usedCredits = data
  },
  'APPEND_CREDITS_TRANSFER_LOGS': (state, data) => {
    state.subvendoLogs = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
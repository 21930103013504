<template>
  <div class="menu-items">
    <v-list
      nav
    >
      <v-list-item
        v-for="[icon, text, path] in links"
        :key="icon"
        :to="path"
        link
      >

        <v-list-item-icon>
          <v-icon>{{ icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ text }}</v-list-item-title>
        </v-list-item-content>

      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <v-list
      nav
      dense
    >
      <v-list-item
        link
        v-logout
      >
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          Logout
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
  export default {
    data: () => ({
      links: [
        ['mdi-view-dashboard', 'Dashboard', '/'],
        ['mdi-slot-machine', 'Machines', '/machines'],
        ['mdi-cash', 'Subvendo Credits', '/subvendo-credits'],
        ['mdi-view-list', 'License Keys', '/pks'],
        ['mdi-format-list-bulleted', 'License Transfer Logs', '/license-transfer-logs'],
        ['mdi-file-swap', 'License Offers', '/pk-offers'],
        ['mdi-file-document-multiple-outline', 'Subscriber Bills', '/recurring-sales'],
        ['mdi-account-cash', 'Payment Accounts', '/payment-accounts'],
        ['mdi-cash-multiple', 'E-cash Sales', '/ecash-sales'],
        ['mdi-cog', 'Settings', '/settings'],
      ],
    }),
  }
</script>

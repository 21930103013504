
import { listWithdrawalReqs } from '@/services/http/withdrawal_requests'

const state = {
  withdrawals: []
}

const getters = {
  allWithdrawals: () => state.withdrawals
}

const actions = {
  async fetchWithdrawals({ commit }, params) {
    const data = await listWithdrawalReqs(params)
    commit('APPEND_WITHDRAWALS', data.withdrawal_requests)
    return {
      total_items: data.total_items,
      total_pages: data.total_pages
    }
  },
  async clearWithdrawals({commit}){
    commit('CLEAR_WITHDRAWALS')
  }
}
const mutations = {
  'SET_WITHDRAWALS': (state, data) => {
    state.withdrawals = data
  },
  'APPEND_WITHDRAWALS': (state, data) => {
    state.withdrawals.push(...data)
  },
  'CLEAR_WITHDRAWALS': (state)=>{
    state.withdrawals = []
  }
}

export default {
  state, getters,
  actions, mutations
}

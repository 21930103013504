import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { Preferences } from '@capacitor/preferences'

import '@/scss/application.scss';
import '@/plugins/global-directives'
import '@/plugins/global-components'
import '@/plugins/vue-observe-visibility'
import '@/plugins/vuetify-confirm'
import '@/plugins/vuetify-toast'
import '@/filters/format_date'


Preferences.migrate().then(() => {
  Vue.config.productionTip = false
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
});

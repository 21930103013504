import http from '@/services/http/http'
import catch_http_error from '@/services/http/catch_http_error'

export async function fetchStats(opts) {
  const url = '/api/v1/device_stats'
  const { data } = await http.get(url,{params: opts})
  return data
}

export async function fetchMachines({ page, per_page, q, is_online}) {
  const url = '/api/v1/devices'
  const res = await http.get(url, {params: { page, per_page, q, is_online}})
  return res.data
}

export async function getMachine(id) {
  try {
    const url = '/api/v1/devices/' + id
    const res = await http.get(url)
    return res.data
  } catch(e) {
    catch_http_error(e)
  }
}

export async function isActivated(cid, uid) {
  try {
    const url = '/api/v1/is-activated?' + 'cid=' + cid + '&uid=' + uid
    const res = await http.get(url)
    return res.data
  } catch(e) {
    catch_http_error(e)
  }
}

export async function updateMachine(id, data) {
  const url = '/api/v1/devices/' + id
  const res = await http.patch(url, data)
  return res.data
}

export async function revokeLicense(machine_id) {
  try {
    const url = '/api/v1/devices/revoke'
    const res = await http.put(url, {id: machine_id})
    return res.data
  } catch(e) {
    catch_http_error(e)
  }
}

export async function cancelRevokeLicense(machind_id) {
  try  {
    const url = '/api/v1/devices/cancel_revoke?id=' + machind_id
    const res = await http.delete(url)
    return res.data
  } catch(e) {
    catch_http_error(e)
  }
}

export async function confirmRevokeLicense(id, token) {
  try {
    const url = '/api/v1/devices/confirm_revoke'
    const res = await http.post(url, { id, token })
    return res.data
  } catch (e) {
    catch_http_error(e)
  }
}

export async function subscribe_sales_report_payment() {
  try {
    const url = '/api/v1/device_stats/subscribe_sales_report'
    const res = await http.post(url)
    return res.data
  } catch (e) {
    catch_http_error(e)
  }
}

export async function get_sales_report_payment () {
  try {
    const url = '/api/v1/device_stats/get_sales_report_payment'
    const res = await http.get(url)
    return res.data
  } catch (e) {
    catch_http_error(e)
  }
}
import { Preferences } from "@capacitor/preferences";
import { STORAGE_AUTH_KEY } from "@/services/constants";
import http from "@/services/http/http";
import catch_http_error from "@/services/http/catch_http_error";

export async function isLoggedIn() {
  const { value } = await Preferences.get({ key: STORAGE_AUTH_KEY });
  if (!value) throw new Error("Please login again");
}
export async function login(emailPassword) {
  const { email, password, captcha_token } = emailPassword;
  const creds = {
    email: email.toLowerCase(),
    password,
    "g-recaptcha-response": captcha_token
  };
  try {
    const loginData = {
      ...creds
    };

    const res = await http.post("/api/v1/sessions", loginData);
    await Preferences.set({
      key: STORAGE_AUTH_KEY,
      value: res.data.token
    }).catch(() => Promise.reject({ email: "Preferences error!" }));
  } catch (e) {
    let res = e.response || { data: {} };
    res.data.email = res.data.email || [];
    res.data.password = res.data.password || [];
    if (!res.status || res.status < 200) {
      return Promise.reject({
        email: "No internet",
        password: "No internet"
      });
    } else {
      if (res.status === 401) {
        if (res.data.has_otp) {
          return Promise.resolve({
            has_otp: res.data.has_otp,
            user_id: res.data.user_id
          });
        } else {
          return Promise.reject({
            email: res.data.email[0],
            password: res.data.password[0]
          });
        }
      } else {
        return Promise.reject({
          email: "Server error",
          password: "Server error"
        });
      }
    }
  }
}

export async function sendOtp(user_id, code) {
  try {
    const url = "/api/v1/sessions/validate_user_with_otp";

    const res = await http.post(url, {
      code,
      user_id
    });
    await Preferences.set({
      key: STORAGE_AUTH_KEY,
      value: res.data.token
    }).catch(() => {
      console.log("error");
      Promise.reject({ code: "Preferences error!" });
    });
  } catch (e) {
    e.response = e.response || {};
    if (e.response.status === 422)
      return Promise.reject({ otp: "Invalid OTP code" });
    else catch_http_error(e);
  }
}

export async function logout() {
  try {
    await http.delete("/api/v1/sessions/0");
  } catch (e) {
    console.log(e);
  }
  await Preferences.remove({ key: STORAGE_AUTH_KEY });
}

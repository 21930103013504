import { Browser } from '@capacitor/browser';

async function openExternal (attrs) {
  const url = attrs.href || attrs.url
  Browser.open({ url })
}

export default {
  bind (el, binding, vnode) {
    el.addEventListener('click', e => {
      e.preventDefault()
      openExternal(vnode.data.attrs)
    })
  }
}

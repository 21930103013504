<template>
  <div class="dashboard-layout">
    <v-app id="inspire">

      <v-navigation-drawer
        v-model="drawer"
        app
      >
        <side-menu></side-menu>
      </v-navigation-drawer>

      <v-app-bar
        color="primary"
        app
      >
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
        <slot name="heading">
          <v-toolbar-title>
            {{ title || 'Dashboard' }}
          </v-toolbar-title>
        </slot>

      </v-app-bar>

      <v-main>
        <!-- content -->
        <slot name="content"></slot>
        <!-- end content -->
      </v-main>
    </v-app>

  </div>
</template>

<script>
  import SideMenu from '@/components/SideMenu'
  export default {
    name: 'DashboardLayout',
    components: {
      SideMenu
    },
    props: ['title'],
    data: () => ({
      drawer: null
    }),
  }
</script>

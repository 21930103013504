const state = {
  filter: {
    q: '',
    from_date: null,
    to_date: null
  }
}

const getters = {
  transferLogsFilter: () => state.filter
}

const actions = {
  setTransferLogsFilter({commit}, filter){
    commit('SET_TRANSFER_LOGS_FILTER', filter)
  },
  resetTransferLogsFilter({ commit }){
    commit('RESET_TRANSFER_LOGS_FILTER')
  }
}

const mutations = {
  'SET_TRANSFER_LOGS_FILTER': (state, filter) => {
    state.filter = filter
  },
  'RESET_TRANSFER_LOGS_FILTER': (state) => {
    state.filter = {
      q: '',
      from_date: '',
      to_date: ''
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
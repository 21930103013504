<template>
  <router-view />
</template>

<script>

  import { initTheme } from '@/services/theme'

  export default {
    name: 'App',
    mounted() {
      initTheme(this.$vuetify)
    }
  };

</script>

export default function (e) {
  e.response = e.response || {}
  if (e.response.status === 422) {
    if (e.response.data.message) {
      alert(e.response.data.message)
    }

    throw new Error(e.response.data.message)
  } else {
    throw e
  }
}

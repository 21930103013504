function slugToTitle(slug) {
  var words = slug.split("_");

  for (var i = 0; i < words.length; i++) {
    var word = words[i];
    words[i] = word.charAt(0).toUpperCase() + word.slice(1);
  }

  return words.join(" ");
}

export default function(e) {
  const errors = e.response.data;
  try {
    const fields = Object.keys(errors);
    return fields.reduce((res, f) => {
      const fTitle = slugToTitle(f)
      res[f] = errors[f].map((e) => `${fTitle} ${e}`);
      return res;
    }, {});
  } catch (e) {
    return errors;
  }
}

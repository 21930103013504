import logout from '@/directives/logout'
import extlink from '@/directives/external-link'
import copyToClipboard from '@/directives/clipboard'

export default {
  install (Vue) {
    Vue.directive('logout', logout)
    Vue.directive('external-link', extlink)
    Vue.directive('clipboard', copyToClipboard)
  }
}

import axios from "axios";
import { Preferences } from "@capacitor/preferences";
import {
  APP_TOKEN,
  BASE_API_URL,
  STORAGE_AUTH_KEY
} from "@/services/constants";
import router from "@/router";

import { getDeviceInfoStr } from "@/services/device-info";

axios.defaults.baseURL = BASE_API_URL;

// inject auth token every request
axios.interceptors.request.use(async req => {
  if (req.url.includes("ngrok")) {
    req.headers["ngrok-skip-browser-warning"] = "skip-browser-warning";
  } else {
    req.headers["Distributor-Code"] = APP_TOKEN;

    try {
      const { value } = await Preferences.get({ key: STORAGE_AUTH_KEY });
      req.headers["Authorization"] = value;
      req.headers["Device"] = await getDeviceInfoStr();
    } catch (e) {
      console.log(e);
    }
  }
  return req;
});

axios.interceptors.response.use(
  res => {
    return res;
  },
  async err => {
    err.response = err.response || {};
    if (
      err.response.status === 401 &&
      err.response.config.url != "/api/v1/sessions"
    ) {
      await Preferences.remove({ key: STORAGE_AUTH_KEY });
      router.push({ name: "Login" });
    } else {
      if (!err.response.status)
        throw new Error("Please check your internet connection");
      else throw err;
    }
  }
);

export default axios;

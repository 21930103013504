import http from '@/services/http/http';
import catch_http_error from '@/services/http/catch_http_error'
import validation_errors from '@/services/http/validation_errors'

export async function fetchUser() {
	const url = 'api/v1/users/me';
	const res = await http.get(url);
	return res.data;
}
export async function updateUser(user) {
	try {
    const res = await http.put('api/v1/users/me', { user });
    return res
  } catch(e) {
    e.response = e.response || {}
    if (e.response.status === 422) {
      if (e.response.data.message)
        catch_http_error(e)
      else
        return Promise.reject(validation_errors(e))
    } else {
      catch_http_error(e)
    }
  }
}

import {
  fetchLicenseOffers,
  patchLicenseOffer
} from "@/services/http/license_transfers";

const state = {
  license_offers: []
};

const getters = {
  allLicenseOffers: () =>
    state.license_offers.filter(lt => lt.accepted === null)
};

const actions = {
  async fetchLicenseOffers({ commit }) {
    const data = await fetchLicenseOffers();
    commit("SET_LICENSE_OFFERS", data);
  },
  async patchLicenseOffer({ commit }, { id, accepted }) {
    try {
      await patchLicenseOffer(id, accepted);
      commit("UPDATE_LICENSE_OFFER", { id, data: { accepted } });
    } catch (e) {
      commit("UPDATE_LICENSE_OFFER", { id, data: { error: e } });
    }
  }
};
const mutations = {
  SET_LICENSE_OFFERS: (state, data) => {
    state.license_offers = data;
  },
  UPDATE_LICENSE_OFFER: (state, { id, data }) => {
    state.license_offers = state.license_offers.map(lt => {
      if (id === lt.id) Object.assign(lt, data);
      return lt;
    });
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};

import http from '@/services/http/http'

export async function listProductKeys(opts) {
  const url = '/api/v1/product_keys'
  const { data } = await http.get(url, {params: opts})

  let keys = data.product_keys.map(k => {
    k.initial = k.code.substr(0, 1).toUpperCase()
    k.selected = false
    if (k.device) {
      let d = k.device
      k.device.display = d.alias || d.cid
      console.log(k)
    }
    return k
  })
  data.product_keys = keys
  return data
}

export async function getProductKey(id) {
  const url = `/api/v1/product_keys/${id}`
  const res = await http.get(url)
  return res.data
}

export async function updateProductKey(id, data) {
  try {
    const url = `/api/v1/product_keys/${id}`
    const res = await http.put(url, data)
    return res.data
  } catch(e) {
    e.response = e.response || {}
    if (e.response.status === 422)
      throw new Error(e.response.data[0])
    else
      throw e
  }
}



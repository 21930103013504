<template>
  <v-list-item-avatar
    :color="computedColor"
    :size="size"
  >
    {{ computedInitial }}
  </v-list-item-avatar>
</template>

<script>

  import randomColor from '@/services/random-color'

  export default {
    props: ['str', 'size'],
    computed: {
      computedInitial() {
        return this.str.substr(0, 1).toUpperCase()
      },
      computedColor() {
       return randomColor(this.str) 
      }
    }
  }

</script>

/*USAGE:

<button
  v-clipboard="textToCopy"
>
  Copy To Clipboard
</button>

*/

import Clipboard from 'clipboard'

export default {
  bind (el, binding, vnode) {
    const { $toast } = vnode.context
    const clip = new Clipboard(el, {
      text: () => binding.value
    })
    clip.on('success', () => $toast('Copied!'))
    clip.on('error', () => $toast("Can't copy text"))
  }
}

import { Preferences } from '@capacitor/preferences'
import { THEME_KEY } from '@/services/constants'

let _$vuetify = {};

export async function isDarkMode() {
  const res = await Preferences.get({key: THEME_KEY})
  return res.value == '1'
}

export async function setDarkMode(isDark) {
  await Preferences.set({key: THEME_KEY, value: isDark})
  _$vuetify.theme.dark = await isDarkMode()
  return _$vuetify.theme.dark
}

export async function initTheme($vuetify) {
  _$vuetify = $vuetify
  const isDark = await isDarkMode()
  $vuetify.theme.dark = isDark
  return isDark
}

export async function themeColors() {
  if (_$vuetify) {
    const isDark = await isDarkMode()
    return _$vuetify.theme.themes[isDark? 'dark' : 'light']
  } else {
    return {}
  }
}

import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from '@/router/routes'
import guards from '@/router/guards'

Vue.use(VueRouter)

const router = new VueRouter({
  routes
})

guards(router)

export default router


import { fetchMachines } from '@/services/http/machines'

const state = {
  machines: []
}
const getters = {
  allMachines: () => state.machines
}

const actions = {
  async fetchMachines({ commit }, query) {
    const { devices, total_items, total_pages } = await fetchMachines(query)
    commit('APPEND_MACHINES', devices)
    return {
      total_items, total_pages
    }
  },
  clearMachines({ commit }) {
    commit('CLEAR_MACHINES')
  }
}

const mutations = {
  'APPEND_MACHINES': (state , machines) => {
    state.machines.push(...machines)
  },
  'CLEAR_MACHINES': (state) => {
    state.machines = []
  }
}

export default {
  state, getters,
  actions, mutations
}

import http from '@/services/http/http'

export async function listSubscBills(opts) {
  const url = '/api/v1/subscriber_bills'
  const { data } = await http.get(url, {params: opts})

  let sb = data.subscriber_bills.map(k => {
    k.initial = k.ref_number.substr(0, 1).toUpperCase()
    k.selected = false
    return k
  })
  data.subscriber_bills = sb
  return data
}

export async function getSubscBill(id) {
  const url = `/api/v1/subscriber_bills/${id}`
  const res = await http.get(url)
  return res.data
}

import { listEcashTransactions } from '@/services/http/ecash_transactions'

const state = {
  ecash_transactions: []
}

const getters = {
  allEcashTransactions: () => state.ecash_transactions
}

const actions = {
  async fetchEcashTransactions({ commit }, params) {
    const data = await listEcashTransactions(params)
    commit('APPEND_ECASH_TRANSACTIONS', data.ecash_transactions)
    return {
      total_items: data.total_items,
      total_pages: data.total_pages,
      total_pending_balance: data.total_pending_balance
    }
  },
  async clearEcashTransactions({commit}){
    commit('CLEAR_ECASH_TRANSACTIONS')
  }
}
const mutations = {
  'APPEND_ECASH_TRANSACTIONS': (state, data) => {
    state.ecash_transactions.push(...data)
  },
  'CLEAR_ECASH_TRANSACTIONS': (state)=>{
    state.ecash_transactions = []
  }
}

export default {
  state, getters,
  actions, mutations
}

import * as _TIMEZONES_ from "timezones.json";

export const APP_TOKEN = process.env.VUE_APP_TOKEN
export const APP_NAME = process.env.VUE_APP_NAME
export const BASE_API_URL = process.env.VUE_APP_BASE_API_URL

export const THEME_KEY = 'dark_theme'
export const STORAGE_AUTH_KEY = `${APP_NAME.replace(/[^\w]/gi, '_').toLowerCase()}_auth_token`

export const CURRENCIES = [
  {text: 'Philippine Peso (PHP) ', value: 'PHP'},
  {text: 'US Dollar (USD)', value: 'USD'},
  {text: 'Indian Rupee (INR)', value: 'INR'},
  {text: 'Indonesian Rupiah (IDR)', value: 'IDR'},
  {text: 'Mexican Peso (MXN)', value: 'MXN'},
  {text: 'Malaysian Ringgit (MYR)', value: 'MYR'},
  {text: 'Brazilian Real (BRL)', value: 'BRL'},
  {text: "Canadian Dollar (CAD)", value: "CAD"},
  {text: "Euro (EUR)", value: "EUR"},
  {text: "United Arab Emirates Dirham (AED)", value: "AED"},
  {text: "Afghan Afghani (AFN)", value: "AFN"},
  {text: "Armenian Dram (AMD)", value: "AMD"},
  {text: "Argentine Peso (ARS)", value: "ARS"},
  {text: "Australian Dollar (AUD)", value: "AUD"},
  {text: "Azerbaijani Manat (AZN)", value: "AZN"},
  {text: "Bosnia-Herzegovina Convertible Mark (BAM)", value: "BAM"},
  {text: "Bangladeshi Taka (BDT)", value: "BDT"},
  {text: "Bulgarian Lev (BGN)", value: "BGN"},
  {text: "Bahraini Dinar (BHD)", value: "BHD"},
  {text: "Burundian Franc (BIF)", value: "BIF"},
  {text: "Brunei Dollar (BND)", value: "BND"},
  {text: "Bolivian Boliviano (BOB)", value: "BOB"},
  {text: "Botswanan Pula (BWP)", value: "BWP"},
  {text: "Belarusian Ruble (BYN)", value: "BYN"},
  {text: "Belize Dollar (BZD)", value: "BZD"},
  {text: "Congolese Franc (CDF)", value: "CDF"},
  {text: "Swiss Franc (CHF)", value: "CHF"},
  {text: "Chilean Peso (CLP)", value: "CLP"},
  {text: "Chinese Yuan (CNY)", value: "CNY"},
  {text: "Colombian Peso (COP)", value: "COP"},
  {text: "Costa Rican Colón (CRC)", value: "CRC"},
  {text: "Cape Verdean Escudo (CVE)", value: "CVE"},
  {text: "Czech Republic Koruna (CZK)", value: "CZK"},
  {text: "Djiboutian Franc (DJF)", value: "DJF"},
  {text: "Danish Krone (DKK)", value: "DKK"},
  {text: "Dominican Peso (DOP)", value: "DOP"},
  {text: "Algerian Dinar (DZD)", value: "DZD"},
  {text: "Estonian Kroon (EEK)", value: "EEK"},
  {text: "Egyptian Pound (EGP)", value: "EGP"},
  {text: "Eritrean Nakfa (ERN)", value: "ERN"},
  {text: "Ethiopian Birr (ETB)", value: "ETB"},
  {text: "British Pound Sterling (GBP)", value: "GBP"},
  {text: "Georgian Lari (GEL)", value: "GEL"},
  {text: "Ghanaian Cedi (GHS)", value: "GHS"},
  {text: "Guinean Franc (GNF)", value: "GNF"},
  {text: "Guatemalan Quetzal (GTQ)", value: "GTQ"},
  {text: "Hong Kong Dollar (HKD)", value: "HKD"},
  {text: "Honduran Lempira (HNL)", value: "HNL"},
  {text: "Croatian Kuna (HRK)", value: "HRK"},
  {text: "Hungarian Forint (HUF)", value: "HUF"},
  {text: "Indonesian Rupiah (IDR)", value: "IDR"},
  {text: "Israeli New Sheqel (ILS)", value: "ILS"},
  {text: "Iraqi Dinar (IQD)", value: "IQD"},
  {text: "Iranian Rial (IRR)", value: "IRR"},
  {text: "Icelandic Króna (ISK)", value: "ISK"},
  {text: "Jamaican Dollar (JMD)", value: "JMD"},
  {text: "Jordanian Dinar (JOD)", value: "JOD"},
  {text: "Japanese Yen (JPY)", value: "JPY"},
  {text: "Kenyan Shilling (KES)", value: "KES"},
  {text: "Cambodian Riel (KHR)", value: "KHR"},
  {text: "Comorian Franc (KMF)", value: "KMF"},
  {text: "South Korean Won (KRW)", value: "KRW"},
  {text: "Kuwaiti Dinar (KWD)", value: "KWD"},
  {text: "Kazakhstani Tenge (KZT)", value: "KZT"},
  {text: "Lebanese Pound (LBP)", value: "LBP"},
  {text: "Sri Lankan Rupee (LKR)", value: "LKR"},
  {text: "Lithuanian Litas (LTL)", value: "LTL"},
  {text: "Latvian Lats (LVL)", value: "LVL"},
  {text: "Libyan Dinar (LYD)", value: "LYD"},
  {text: "Moroccan Dirham (MAD)", value: "MAD"},
  {text: "Moldovan Leu (MDL)", value: "MDL"},
  {text: "Malagasy Ariary (MGA)", value: "MGA"},
  {text: "Macedonian Denar (MKD)", value: "MKD"},
  {text: "Myanma Kyat (MMK)", value: "MMK"},
  {text: "Macanese Pataca (MOP)", value: "MOP"},
  {text: "Mauritian Rupee (MUR)", value: "MUR"},
  {text: "Mozambican Metical (MZN)", value: "MZN"},
  {text: "Namibian Dollar (NAD)", value: "NAD"},
  {text: "Nigerian Naira (NGN)", value: "NGN"},
  {text: "Nicaraguan Córdoba (NIO)", value: "NIO"},
  {text: "Norwegian Krone (NOK)", value: "NOK"},
  {text: "Nepalese Rupee (NPR)", value: "NPR"},
  {text: "New Zealand Dollar (NZD)", value: "NZD"},
  {text: "Omani Rial (OMR)", value: "OMR"},
  {text: "Panamanian Balboa (PAB)", value: "PAB"},
  {text: "Peruvian Nuevo Sol (PEN)", value: "PEN"},
  {text: "Philippine Peso (PHP)", value: "PHP"},
  {text: "Pakistani Rupee (PKR)", value: "PKR"},
  {text: "Polish Zloty (PLN)", value: "PLN"},
  {text: "Paraguayan Guarani (PYG)", value: "PYG"},
  {text: "Qatari Rial (QAR)", value: "QAR"},
  {text: "Romanian Leu (RON)", value: "RON"},
  {text: "Serbian Dinar (RSD)", value: "RSD"},
  {text: "Russian Ruble (RUB)", value: "RUB"},
  {text: "Rwandan Franc (RWF)", value: "RWF"},
  {text: "Saudi Riyal (SAR)", value: "SAR"},
  {text: "Sudanese Pound (SDG)", value: "SDG"},
  {text: "Swedish Krona (SEK)", value: "SEK"},
  {text: "Singapore Dollar (SGD)", value: "SGD"},
  {text: "Somali Shilling (SOS)", value: "SOS"},
  {text: "Syrian Pound (SYP)", value: "SYP"},
  {text: "Thai Baht (THB)", value: "THB"},
  {text: "Tunisian Dinar (TND)", value: "TND"},
  {text: "Tongan Paʻanga (TOP)", value: "TOP"},
  {text: "Turkish Lira (TRY)", value: "TRY"},
  {text: "Trinidad and Tobago Dollar (TTD)", value: "TTD"},
  {text: "New Taiwan Dollar (TWD)", value: "TWD"},
  {text: "Tanzanian Shilling (TZS)", value: "TZS"},
  {text: "Ukrainian Hryvnia (UAH)", value: "UAH"},
  {text: "Ugandan Shilling (UGX)", value: "UGX"},
  {text: "Uruguayan Peso (UYU)", value: "UYU"},
  {text: "Uzbekistan Som (UZS)", value: "UZS"},
  {text: "Venezuelan Bolívar (VEF)", value: "VEF"},
  {text: "Vietnamese Dong (VND)", value: "VND"},
  {text: "CFA Franc BEAC (XAF)", value: "XAF"},
  {text: "CFA Franc BCEAO (XOF)", value: "XOF"},
  {text: "Yemeni Rial (YER)", value: "YER"},
  {text: "South African Rand (ZAR)", value: "ZAR"},
  {text: "Zambian Kwacha (ZMK)", value: "ZMK"},
  {text: "Zimbabwean Dollar (ZWL)", value: "ZWL"}
]

export const TIMEZONES = _TIMEZONES_.reduce((result, tz) => {
  return result.concat(tz.utc)
}, []).sort()


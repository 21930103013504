import http from '@/services/http/http'

export async function getSubvendoCredits () {
  const url = "/api/v1/subvendos/available_and_used_credits"
  const res = await http.get(url)
  return res.data || []
}
export async function getSubvendoTransferLogs() {
  const url = "/api/v1/subvendo_credits_transfer_logs"
  const res = await http.get(url)
  return res.data || []
}
export async function transferSubvendoCredits(opts) {
  const url = "/api/v1/subvendo_credits_transfer_logs"
  const res = await http.post(url,opts)
  return res.data || []
}
export async function revokeSubvendoCredits(id) {
  const url = `/api/v1/subvendos/${id}/revoke`
  const res = await http.post(url)
  return res
}
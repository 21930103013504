import { Dialog } from '@capacitor/dialog';

function Install (Vue) {
  const property = '$toast'
  function show (title, message) {
    if (!message) {
      message = title
      title = 'Notification'
    }
    return Dialog.alert({ title, message })
  }

  Vue.prototype[property] = show
}


export default Install


import router from '@/router'

async function clickListener (el, binding, vnode) {
  const title = 'Confirm Sign Out'
  const message = 'Are you sure you want to logout?'
  const { $confirm } = vnode.context

  const yes = await $confirm(message, { title })

  if (yes) {
    router.push({name: 'Logout'})
  }

}

export default {
  bind (el, binding, vnode) {
    el.addEventListener('click', e => {
      e.preventDefault()
      clickListener(el, binding, vnode)
    })
  }
}

<template>
  <span class="ado-logo">
    ADO<span class="pi primary--text">PI</span>SOFT
  </span>
</template>

<script>
  import "@fontsource/righteous"
  export default {
    name: 'Logo'
  }
</script>

<style lang="scss" scoped>

  @import '~vuetify/src/components/VStepper/_variables.scss';

  .ado-logo {
    font-family: 'Righteous';
    /*color: #000;*/

    .pi {
      /*color: $primary-color;*/
    }

  }
</style>


import http from '@/services/http/http'
import catch_http_error from '@/services/http/catch_http_error'

export async function fetchLicenseOffers() {
  const url = '/api/v1/license_transfers'
  const res = await http.get(url)
  return res.data
}

export async function patchLicenseOffer(id, accept_or_decline) {
  try {
    const url = `/api/v1/license_transfers/${id}`
    const res = await http.patch(url, {license_transfer: {accepted: accept_or_decline}})
    return res
  } catch(e) {
    e.response = e.response || {}
    if (e.response.status === 422)
      throw new Error(e.response.data[0])
    else
      throw e
  }
}

export async function transferLicense(id, email) {
  try {
    const url = '/api/v1/license_transfers'
    const res = await http.post(url, { id, email })
    return res
  } catch(e) {
    catch_http_error(e)
  }
}

export async function cancelTransfer(id) {
  const url = '/api/v1/license_transfers/' + id
  const res = await http.delete(url)
  return res
}

export async function fetchLicenseTranferLogs(opts){
  const url = '/api/v1/license_transfers/transfer_logs'
  const res = await http.get(url, {params: opts})
  return res
}


import { isLoggedIn } from '@/services/http/auth'

const EXTERNAL_PAGES = [
  'Login',
  'Register',
  'ConfirmAccount',
  'RequestPasswordReset',
  'ResetPassword',
  'ResendConfirmationInstructions'
]

export default function initGuards (router) {
  router.beforeEach((to, from, next) => {
    if (!EXTERNAL_PAGES.includes(to.name)) {
      isLoggedIn()
        .then(() => next())
        .catch(() => {
          next({name: 'Login'})
        })
    } else {
      isLoggedIn()
        .then(() => {
          console.log("Already logged in!!!")
          next('/')
        })
        .catch(() => {
          next()
        })
    }
  })
}

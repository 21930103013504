import Vue from 'vue'
import Vuex from 'vuex'

import product_keys from './modules/product_keys'
import license_offers from './modules/license_offers'
import machines from './modules/machines'
import machine_stats from './modules/machine_stats.js'
import subscriber_bills from './modules/subscriber_bills.js'
import wallet_transactions from './modules/wallet_transactions.js'
import withdrawal_requests from './modules/withdrawal_requests.js'
import license_transfer_logs from './modules/license_transfer_logs.js'
import subvendo_credits from './modules/subvendo_credits.js'
import ecash_transactions from './modules/ecash_transactions.js'

import user from './modules/user'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    product_keys,
    license_offers,
    machines,
    machine_stats,
    subscriber_bills,
    wallet_transactions,
    withdrawal_requests,
    license_transfer_logs,
    subvendo_credits,
    ecash_transactions,
    user
  }
})

import Vue from 'vue'
import DashboardLayout from '@/components/DashboardLayout'
import RandListItemAvatar from '@/components/RandListItemAvatar'
import Loading from '@/components/LoadingCircular'

const GlobalComponents = {
  install(Vue) {
    Vue.component('dashboard-layout', DashboardLayout)
    Vue.component('rand-list-item-avatar', RandListItemAvatar)
    Vue.component('loading-circular', Loading)
  }
}

Vue.use(GlobalComponents)

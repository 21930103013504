
import { listSubscBills } from '@/services/http/subscriber_bill'

const state = {
  subscriber_bill: {},
  subscriber_bills: [],
  select_mode: false,
  filter: {
    q: '',
    status: null,
    date_from: null,
    date_to: null,
    per_page: 15,
  }
}

const getters = {
  allSubscBills: () => state.subscriber_bills,
  selectedSubscBills: () => state.subscriber_bills.filter(p => p.selected),
  subscBillsFilter: () => state.filter,
  subscBillSelectMode: () => state.select_mode
}

const actions = {

  async fetchSubscBills({ commit }, params) {
    const data = await listSubscBills(params)
    commit('APPEND_SUBSC_BILLS', data.subscriber_bills)
    return {
      total_items: data.total_items,
      total_pages: data.total_pages,
      total_paid: data.total_paid,
      total_unpaid: data.total_unpaid
    }
  },

  clearSubscBills({ commit }) {
    commit('CLEAR_SUBSC_BILLS')
  },

  selectAllSubscBills({ commit, state }, val) {
    let subscriber_bills = state.subscriber_bills
    subscriber_bills.forEach(k => (k.selected = val))
    commit('SET_SUBSC_BILLS', subscriber_bills)
  },

  setSubscBillsFilter({ commit}, filter) {
    commit('SET_SUBSC_BILLS_FILTER', filter)
  },

  resetSubscBillsFilter({ commit }) {
    commit('RESET_SUBSC_BILLS_FILTER')
  }

}

const mutations = {
  'APPEND_SUBSC_BILLS': (state, data) => {
    state.subscriber_bills.push(...data)
  },
  'SET_SUBSC_BILLS': (state, data) => {
    state.subscriber_bills = data
  },
  'CLEAR_SUBSC_BILLS': (state) => {
    state.subscriber_bills = []
  },
  'SET_SUBSC_BILLS_FILTER': (state, filter) => {
    state.filter = filter
  },
  'SET_SUBSC_BILLS_SELECT_MODE': (state, mode) => {
    state.select_mode = mode
  },
  'DESELECT_ALL_SUBSC_BILLS': (state) => {
    state.subscriber_bills.forEach(p => p.selected = false)
  },
  'RESET_SUBSC_BILLS_FILTER': (state) => {
    state.filter = {
      q: '',
      status: null,
      date_from: null,
      date_to: null,
      per_page: 15,
    }
  }

}

export default {
  state,
  getters,
  actions,
  mutations
}

<template>
  <div class="menu-items">
    <v-app-bar
      flat
    >
      <div class="text-center">
        <logo class="logo" />

      </div>
    </v-app-bar>
    <v-divider />
    <menu-items />
  </div>

</template>

<script>
  import Logo from '@/components/Logo'
  import MenuItems from '@/components/MenuItems'

  export default {
    name: 'SideMenu',
    components: {
      Logo,
      MenuItems
    }
  }
</script>

<style lang="scss" scoped>
  div {
    width: 100%;
  }
  .logo {
    font-size: 2em;
  }
</style>

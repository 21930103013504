
import { listWalletTransactions } from '@/services/http/wallet_transactions'

const state = {
  wallet_transactions: []
}

const getters = {
  allWalletTransactions: () => state.wallet_transactions
}

const actions = {
  async fetchWalletTransactions({ commit }, params) {
    const data = await listWalletTransactions(params)
    commit('APPEND_WALLET_TRANSACTIONS', data.wallet_transactions)
    return {
      total_items: data.total_items,
      total_pages: data.total_pages
    }
  },
  async clearWalletTransactions({commit}){
    commit('CLEAR_WALLET_TRANSACTIONS')
  }
}
const mutations = {
  'SET_WALLET_TRANSACTIONS': (state, data) => {
    state.wallet_transactions = data
  },
  'APPEND_WALLET_TRANSACTIONS': (state, data) => {
    state.wallet_transactions.push(...data)
  },
  'CLEAR_WALLET_TRANSACTIONS': (state)=>{
    state.wallet_transactions = []
  }
}

export default {
  state, getters,
  actions, mutations
}
